export enum eResource {
  SCHEDULE_MY_AGENDA = 'schedule_my_agenda',
  SCHEDULE_MINISTRIES = 'schedule_ministries',
  SCHEDULE_EVENTS = 'schedule_events',
  SCHEDULE_EDITOR = 'schedule_editor',

  KIDS_MY_FAMILY = 'kids_my_family',
  KIDS_ROOMS = 'kids_rooms',
  KIDS_FAMILIES = 'kids_families',
  KIDS_SETTINGS = 'kids_settings',
  KIDS_REPORTS = 'kids_reports',

  CALENDAR = 'calendar',
  CALENDAR_ROOMS = 'calendar_rooms',

  GROUP = 'group',
  GROUP_REPORTS = 'group_reports',
  GROUP_ALL = 'group_all',
  GROUP_NETWORKS = 'group_networks',
  GROUP_SETTINGS = 'group_settings',

  CONNECT = 'connect',
  CONNECT_SETTINGS = 'connect_settings',
  CONNECT_BANNERS = 'connect_banners',

  PEOPLE_PROFILE = 'people_profile',

  WORSHIP_LIBRARY = 'worship_library'
}
